.radio {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #307a85;
    padding: 2px;
    display: inline-block;
    margin-right: 5px;
}

.checked {
    height: 100%;
    width: 100%;
    background-color: #307a85;
    border-radius: 50%;
}

.label {
    color: #307a85;
    margin-bottom: 5px;
}

.lightMode {
    color: #fff;
}
