.spinnerContainer {
    padding: 80px 0;
    text-align: center;
}

.spinner {
    color: #307a85 ;
    height: 100px !important;
    width: 100px !important;
}

.text {
    font-size: 20px;
    font-weight: 500;
    color: #307a85 ;
    margin-top: 20px;
}

.pageTitle {
    color: #307a85 ;
    font-size: 30px;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
