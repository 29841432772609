.cardContainer {
    text-align: center;
}

.card {
    max-width: 900px;
    width: 100%;
    padding: 0 20px;
    display: inline-block !important;
}

.textInput {
    text-align: left;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

.buttonContainer {
    margin-top: 40px;
    text-align: center;
}

.pageTitle {
    color: #307a85;
    font-size: 30px;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.controlLabel {
    color: #307a85;
}

.input {
    background-color: #8fd0cf !important;
    box-shadow: none !important;
    border: 2px #307a85 solid !important;
    color: #307a85 !important;
}
