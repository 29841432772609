.row {
    padding: 0 5px 10px;
    font-size: 0;
}
.rowContainer {
    text-align: center;
}
.tile {
    display: inline-block;
    padding: 0 5px;
}

.nftContainer {
    background-color: #307a85;
    width: 200px;
    height: 230px;
}

.nftName {
    font-size: 16px;
    color: #fff;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pageTitle {
    color: #307a85 ;
    font-size: 30px;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.spinnerContainer {
    padding: 80px 0;
    text-align: center;
}

.spinner {
    color: #307a85 ;
    height: 100px !important;
    width: 100px !important;
}

.spinnerText {
    font-size: 20px;
    font-weight: 500;
    color: #307a85 ;
    margin-top: 20px;
}
