.cardContainer {
    text-align: center;
}

.card {
    margin-top: 10px;
    max-width: 900px;
    width: 100%;
    padding: 0 0 20px;
    display: inline-block !important;
    border-radius: 0.375rem;
    background-color: #BBE2E2;
    overflow: hidden;
}

.avatarContainer {
    text-align: center;
}

.title {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

.subtitle {
    font-size: 18px;
    font-weight: 500;
    color: #307a85;
    text-align: left;
}

.addressText {
    font-size: 16px;
    text-align: left;
    margin-bottom: 0;
    color: #307a85;
    margin-left: 30px;
}

.divider {
    border-bottom: 1px solid #dadada;
    width: 100%;
    margin: 20px 0;
}

.printOptions {
    text-align: left;
    color: #307a85;
    margin-left: 30px;
}

.printOptionsCost {
    display: inline-block;
    width: 100px;
    text-align: right;
}

.total {
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
    color: #307a85;
}

.buttonContainer {
    text-align: center;
    margin-top: 40px;
}

.nftContainer {
    background-color: #307a85;
    width: 200px;
    height: 230px;
    display: inline-block;
}

.nftName {
    font-size: 16px;
    color: #fff;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pageTitle {
    color: #307a85;
    font-size: 30px;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bottomPadding {
    padding-bottom: 20px;
}

.header {
    padding: 16px 20px;
    font-size: 20px;
    color: #fff;
    background-color: #307a85;
    margin-bottom: 20px;
    text-align: left;
}

.rowPadding {
    padding-left: 20px;
    padding-right: 20px;
}
