.button {
    background-color: #307a85 !important;
    color: #fff !important;
    border: none !important;
}

.fullWidth {
    width: 100%;
}

.buttonLight {
    color: #307a85 !important;
    background-color: #8fd0cf !important;
}
