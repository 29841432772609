.imageContainer {
    height: 80px;
    overflow: hidden;
    position: relative;
    width: 150px;
    margin-left: 60px;
}

.image {
    position: absolute;
    top: -6px;
}

.navbar {
    padding: 5px 10px 5px 0;
    background-color: #fff;
}

@media (max-width: 576px) {
    .imageContainer {
        width: 100vw;
        margin-left: 0px;
    }

    .image {
        position: absolute;
        top: -6px;
        left: 0;
        right: 0;
        margin: auto
    }
}
