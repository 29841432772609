.container {
    padding: 80px 0;
    text-align: center;
}

.title {
    font-size: 28px;
    color: #fff;
    font-weight: bold;
}

.subtitle {
    font-size: 18px;
    color: #307a85;
}

.pageTitle {
    color: #307a85;
    font-size: 30px;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button {
    padding: 12px 18px;
}
