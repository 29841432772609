.content {
    padding: 20px 0;
    background-color: #fff;
}

.avatarContainer {
    text-align: center;
}

.finishContainer {
    text-align: center;
    margin-bottom: 10px;
}

.finishButtonContainer {
    width: 150px;
    display: inline-block;
}

.cardContainer {
    text-align: center;
}

.card {
    margin-top: 10px;
    max-width: 900px;
    width: 100%;
    display: inline-block !important;
}

.radio {
    width: fit-content;
}

.title {
    font-size: 18px;
    font-weight: 500;
    color: #307a85;
}

.section {
    text-align: left;
}

.input {
    margin-left: 30px;
}

.buttonContainer {
    text-align: center;
    margin-top: 40px;
}

.total {
    margin-bottom: 10px;
    font-size: 18px;
    color: #307a85;
    font-weight: 500;
}

.nftContainer {
    background-color: #307a85;
    width: 200px;
    height: 230px;
    display: inline-block;
}

.nftName {
    font-size: 16px;
    color: #fff;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pageTitle {
    color: #307a85;
    font-size: 30px;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*Force bootstrap styles in prod create bespoke components*/
.accordionItem {
    border-color: #307a85 !important;
}

.accordionHeader button {
    background-color: #307a85 !important;
    color: #fff !important;
    font-size: 20px;
    border: none !important;
    box-shadow: none !important;
}

.accordionHeader button:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordionBody {
    /*background-color: #8fd0cf;*/
    background-color: #BBE2E2 !important;
}

.divider {
    border-bottom: 1px solid #fff;
    width: 100%;
}
